export default {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '/api/',
  getExecutorsEndpoint: '/v1/accounts/',
  getContractsEndpoint: '/v1/contracts/',
  getContractEndpoint: '/v1/contracts/:id/',
  getApplicationsEndpoint: '/v1/applications/',
  getApplicationEndpoint: '/v1/applications/:id/',
  editApplicationEndpoint: '/v1/applications/:id/',
  deleteApplicationEndpoint: '/v1/applications/:id/',
  createApplicationEndpoint: '/v1/applications/',
  getApplicationStatusesEndpoint: '/v1/applications/statuses/',
  getApplicationActivitiesEndpoint: '/v1/applications/:id/activities/',
  postApplicationCommentEndpoint: '/v1/applications/:id/comment/',
};
