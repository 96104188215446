// ** Toolkit
import { configureStore } from '@reduxjs/toolkit';

// ** Reducers
import contracts from './contracts';
import applications from './applications';
import helpers from './helpers';

export const store = configureStore({
  reducer: {
    contracts,
    applications,
    helpers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
