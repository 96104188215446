// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get executors
export const getExecutors = createAsyncThunk('helpers/getExecutors', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getExecutorsEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      executors: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Application Statuses
export const getApplicationStatuses = createAsyncThunk('helpers/getApplicationStatuses', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getApplicationStatusesEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      applicationStatuses: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const helpersSlice = createSlice({
  name: 'helpers',
  initialState: {
    isLoadingExecutors: false,
    isLoadingApplicationStatuses: false,
    executors: [],
    applicationStatuses: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExecutors.pending, (state) => {
        state.isLoadingExecutors = true;
      })
      .addCase(getExecutors.fulfilled, (state, action) => {
        state.executors = action.payload.executors;

        state.isLoadingExecutors = false;
      })
      .addCase(getExecutors.rejected, (state) => {
        state.isLoadingExecutors = false;
      });
    builder
      .addCase(getApplicationStatuses.pending, (state) => {
        state.isLoadingApplicationStatuses = true;
      })
      .addCase(getApplicationStatuses.fulfilled, (state, action) => {
        state.applicationStatuses = action.payload.applicationStatuses;

        state.isLoadingApplicationStatuses = false;
      })
      .addCase(getApplicationStatuses.rejected, (state) => {
        state.isLoadingApplicationStatuses = false;
      });
  },
});

export default helpersSlice.reducer;
