// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Utils
import localStorageService from 'src/@core/utils/local-storage';

// ** Get Applications
export const getApplications = createAsyncThunk('applications/getApplications', async (params, { rejectWithValue }) => {
  const { ...other } = params;

  for (const key of Object.keys(other)) {
    if (other[key] === '') {
      delete other[key];
    }
  }

  try {
    const response = await http.get(apiConfig.getApplicationsEndpoint, {
      params: {
        ...other,
      },
    });
    const data = response.data;

    return {
      applications: data.results || [],
      count: data.count || 0,
      filters: params || {},
    };
  } catch (error) {
    return rejectWithValue({
      error: error.response.data,
      filters: params || {},
    });
  }
});

// ** Create Application
export const createApplication = createAsyncThunk('applications/createApplication', async (params, { rejectWithValue }) => {
  try {
    const response = await http.post(apiConfig.createApplicationEndpoint, {
      ...params,
    });
    const data = response.data;

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Edit Application
export const editApplication = createAsyncThunk('applications/editApplication', async (params, { rejectWithValue }) => {
  try {
    const response = await http.patch(apiConfig.editApplicationEndpoint.replace(':id', params.id), params);
    const data = response.data;

    return {
      application: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Delete Application
export const deleteApplication = createAsyncThunk(
  'applications/deleteApplication',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.delete(apiConfig.deleteApplicationEndpoint.replace(':id', params.id));
      const data = response.data;

      dispatch(getApplications());

      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Application
export const getApplication = createAsyncThunk('applications/getApplication', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getApplicationEndpoint.replace(':id', params.id));
    const data = response.data;

    return {
      application: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Application Activities
export const getApplicationActivities = createAsyncThunk(
  'applications/getApplicationActivities',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getApplicationActivitiesEndpoint.replace(':id', params.id), {
        params: {
          page_size: 999,
        },
      });
      const data = response.data;

      return {
        applicationActivities: data.results || [],
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Post Application Comment
export const postApplicationComment = createAsyncThunk(
  'applications/postApplicationComment',
  async (params, { rejectWithValue, dispatch, getState }) => {
    const applicationId = getState().applications.application.id;

    try {
      const response = await http.post(apiConfig.postApplicationCommentEndpoint.replace(':id', params.id), {
        action: params.action,
        ...(params.replyTo
          ? {
              replyTo: params.replyTo,
            }
          : {}),
      });
      const data = response.data;

      dispatch(getApplicationActivities({ id: applicationId }));

      return {
        comment: data || {},
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState: {
    isLoadingApplications: false,
    isLoadingApplication: false,
    isLoadingApplicationActivities: false,
    applications: [],
    application: {},
    applicationsCount: 0,
    applicationsError: false,
    applicationError: false,
    applicationActivitiesError: false,
    applicationsFilters: {
      page: 1,
      page_size: localStorageService.get('applicationsFilters')?.page_size || 25,
      status: localStorageService.get('applicationsFilters')?.status || '',
      ordering: localStorageService.get('applicationsFilters')?.ordering || 'id',
    },
    applicationActivities: [],
  },
  reducers: {
    resetApplication: (state) => {
      state.isLoadingApplication = false;
      state.applicationError = false;
      state.application = {};
      state.applicationActivities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplications.pending, (state) => {
        state.isLoadingApplications = true;
        state.applicationsError = false;
      })
      .addCase(getApplications.fulfilled, (state, action) => {
        state.applications = action.payload.applications;
        state.applicationsCount = action.payload.count;
        state.applicationsFilters = action.payload.filters;

        state.isLoadingApplications = false;

        localStorageService.set('applicationsFilters', action.payload.filters);
      })
      .addCase(getApplications.rejected, (state, action) => {
        state.applications = [];
        state.applicationsCount = 0;
        state.applicationsFilters = action.payload.filters;
        state.applicationsError = action.error?.payload?.error?.detail || 'Произошла ошибка';

        state.isLoadingApplications = false;

        localStorageService.set('applicationsFilters', action.payload.filters);
      });
    builder
      .addCase(getApplication.pending, (state) => {
        state.isLoadingApplication = true;

        state.applicationError = false;
      })
      .addCase(getApplication.fulfilled, (state, action) => {
        state.application = action.payload.application;

        state.isLoadingApplication = false;
      })
      .addCase(getApplication.rejected, (state, action) => {
        state.isLoadingApplication = false;

        state.applicationError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getApplicationActivities.pending, (state) => {
        state.isLoadingApplicationActivities = true;

        state.applicationActivitiesError = false;
      })
      .addCase(getApplicationActivities.fulfilled, (state, action) => {
        state.applicationActivities = action.payload.applicationActivities;

        state.isLoadingApplicationActivities = false;
      })
      .addCase(getApplicationActivities.rejected, (state, action) => {
        state.isLoadingApplicationActivities = false;

        state.applicationActivitiesError = action.payload?.detail || 'Произошла ошибка';
      });
  },
});

export const { resetApplication } = applicationsSlice.actions;

export default applicationsSlice.reducer;
